
































import Vue from "vue";
import * as $ from 'jquery';

export default Vue.extend({
    props: {
        target: { type: String },
    },
    data() {
        return {
            isOpen: false,
        }
    },
    mounted() {
        this.setTargetClass();
    },
    computed: {
        buttonClasses() {
            let statusClass = this.isOpen ? 'open' : 'closed';
            return ['burger-button', statusClass];
        }
    },
    methods: {
        click() {
            this.isOpen = !this.isOpen;

            this.setTargetClass();
        },

        setTargetClass() {
            if (this.isOpen) {
                $(this.target).removeClass("closed");
                $(this.target).addClass("open");
            } else {
                $(this.target).removeClass("open");
                $(this.target).addClass("closed");
            }
        },
    }
});
