
































import Vue from "vue";

export default Vue.extend({
    props: {
        direction: { type: String, default: 'bottom' },
    },
    computed: {
        style() {
            switch (this.direction) {
                case 'bottom':
                    return {};
                case 'up':
                    return { transform: 'rotate(180deg)' };
                case 'right':
                    return { transform: 'rotate(-90deg)' };
                case 'left':
                    return { transform: 'rotate(90deg)' };
            }
        }
    }
});
