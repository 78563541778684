var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type == "link"
    ? _c(
        "a",
        {
          class: _vm.classes,
          attrs: { href: _vm.target },
          on: { click: _vm.doAction }
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "button",
        { class: _vm.classes, on: { click: _vm.doAction } },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }