var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      ref: "button",
      class: _vm.buttonClasses,
      attrs: { "aria-label": "Open/Close menu" },
      on: {
        click: function($event) {
          return _vm.click()
        }
      }
    },
    [
      _c("div", { staticClass: "burger-button-bar" }),
      _vm._v(" "),
      _c("div", { staticClass: "burger-button-bar" }),
      _vm._v(" "),
      _c("div", { staticClass: "burger-button-bar" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }