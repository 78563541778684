
































import Vue from "vue";
import * as $ from 'jquery';

export default Vue.extend({
    props: {
        classes: { default() { return [] } },
        hideIfVisible: { type: String },
    },
    data() {
        return {
            isVisible: false,
        }
    },
    computed: {
        hideIfElementVisible() {
            if (this.hideIfVisible) {
                return $(this.hideIfVisible);
            } else {
                return null;
            }
        },

        allClasses() {
            let values = { 'hidden': !this.isVisible };

            if (this.classes) {
                Object.assign(values, this.classes);
            }

            return values;
        },
    },
    mounted() {
        this.isVisible = this.checkIfIsVisible();

        $(window).scroll(() => {
            this.isVisible = this.checkIfIsVisible()
        });
    },
    methods: {
        checkIfIsVisible() {
            if (this.hideIfElementVisible) {
                let el = this.hideIfElementVisible;

                let screenTop = window.pageYOffset;
                var screenBottom = screenTop + window.innerHeight;

                let elTop = el.offset().top;
                let elBottom = elTop + el.height();

                let elIsVisible = screenBottom > elTop && screenTop < elBottom;

                return !elIsVisible;
            } else {
                return true;
            }
        }
    }
});
