


































import Vue from "vue";

import * as $ from "jquery";

export default Vue.extend({
    props: {
        action: { type: String, required: true }, // One of [scroll-to, click]
        target: { type: String, required: true },
        type: { default: "link" }, // Either button or link
        classes: { type: String, default: "" },
    },
    methods: {
        doAction(event) {
            let actions = {
                "click": this.click,
                "scroll-to": this.scrollTo,
            }

            actions[this.action]();

            event.preventDefault();
        },

        click() {
            $(this.target).trigger("click");
        },

        scrollTo() {
            $('html, body').animate({ scrollTop: $(this.target).offset().top }, 500);
        },

    }
});
